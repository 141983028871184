import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "../../utilities";
import { P } from "../typography/p";

const IconTitle = styled.div({
  textTransform: "uppercase",
  marginBottom: 16,
  fontWeight: "bold",
  letterSpacing: 1,
});

interface IIconBlockProps {
  icon: any;
  title: string;
  content: string;
}

export const IconBlock: React.SFC<IIconBlockProps> = ({
  icon: Icon,
  title,
  content,
}) => (
  <>
    <div>
      <Icon
        css={{
          height: 50,
          width: 50,
          marginBottom: 16,
        }}
      />
    </div>
    <IconTitle>{title}</IconTitle>
    <P>{content}</P>
  </>
);

IconBlock.displayName = "IconBlock";
