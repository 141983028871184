import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroVideo } from "../components/hero-video";
import { ContentFade } from "../components/content-fade";
import { IFluidImage, IChildImageSharpFluid } from "../interfaces";
import GatsbyImage from "gatsby-image";
import { Banner } from "../components/banner";
import { ReactComponent as ExperienceIcon } from "../media/images/icons/experience.svg";
import { ReactComponent as IntegrityIcon } from "../media/images/icons/integrity.svg";
import { ReactComponent as PersonalIcon } from "../media/images/icons/personal.svg";
import { FlexBlocks, FlexBlock } from "../components/flex-blocks";
import { IconBlock } from "../components/icon-block";
import { Wrapper } from "../components/wrapper";
import { colors } from "../utilities";
import { ButtonWrapper } from "../components/button";
import { Link } from "../components/link";
import { P, PSize } from "../components/typography/p";

const Icons = {
  experience: ExperienceIcon,
  integrity: IntegrityIcon,
  personal: PersonalIcon,
};

const BulletWrapper = styled.div({
  display: "flex",
  textTransform: "uppercase",
  letterSpacing: 1,
  ul: {
    listStyle: "none",
    "&:first-of-type": {
      paddingLeft: 0,
    },
  },
  li: {
    paddingLeft: "1em",
    textIndent: "-.7em",
    lineHeight: 1.75,
    "&:before": {
      content: '"• "',
      color: colors.orange.base,
    },
  },
});

const FlexBlockPadded = styled(FlexBlock)({
  paddingLeft: 8,
  paddingRight: 8,
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-child": {
    paddingRight: 0,
  },
});

export interface IIndexPageProps {
  data: IIndexPageQuery;
  location: Location;
}

const IndexPage: React.SFC<IIndexPageProps> = ({ data }) => {
  const maxServices = 4;
  let serivcesArray: string[][] = [];
  for (
    var i = 0;
    i < data.content.frontmatter.services.length;
    i += maxServices
  ) {
    serivcesArray.push(
      data.content.frontmatter.services.slice(i, i + maxServices),
    );
  }

  return (
    <Layout>
      <SEO title="Home" />
      <HeroVideo
        videoSrc={data.homeVideo.publicURL}
        title={data.content.frontmatter.title}
      />
      <Banner />
      <ContentFade
        imgSrc={data.content.frontmatter.teamImg.childImageSharp.fluid.src}
      >
        <h3>{data.content.frontmatter.teamTitle}</h3>
        <P>{data.content.frontmatter.teamContent}</P>
        <ButtonWrapper.Primary>
          <Link to="/team">{data.content.frontmatter.teamButton}</Link>
        </ButtonWrapper.Primary>
      </ContentFade>
      <Wrapper id="about" css={{ paddingTop: 64, paddingBottom: 64 }}>
        <h3>{data.content.frontmatter.aboutTitle}</h3>
        <P size={PSize.large}>{data.content.frontmatter.aboutContent}</P>
        <FlexBlocks>
          {data.content.frontmatter.iconBlocks.map((ib, i) => (
            <FlexBlockPadded key={i}>
              <IconBlock
                icon={Icons[ib.icon]}
                title={ib.title}
                content={ib.content}
              />
            </FlexBlockPadded>
          ))}
        </FlexBlocks>
      </Wrapper>
      <GatsbyImage
        sizes={data.content.frontmatter.bannerImg.childImageSharp.fluid}
        css={{
          maxHeight: 400,
          img: {
            objectPosition: "top center!important",
          },
        }}
      />
      <Wrapper id="services" css={{ paddingTop: 64, paddingBottom: 64 }}>
        <h3>{data.content.frontmatter.serviceTitle}</h3>
        <P size={PSize.large}>{data.content.frontmatter.serviceContent}</P>
        <BulletWrapper>
          {serivcesArray.map((sa, i) => (
            <ul key={i}>
              {sa.map((s, i2) => (
                <li key={i2}>{s}</li>
              ))}
            </ul>
          ))}
        </BulletWrapper>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

interface IIndexPageQuery {
  homeVideo: {
    publicURL: string;
  };
  content: {
    frontmatter: {
      title: string;
      teamTitle: string;
      teamContent: string;
      teamButton: string;
      teamImg: IChildImageSharpFluid;
      bannerImg: IChildImageSharpFluid;
      iconBlocks: { content: string; icon: string; title: string }[];
      aboutTitle: string;
      aboutContent: string;
      serviceTitle: string;
      serviceContent: string;
      services: string[];
    };
  };
}

export const query = graphql`
  query IndexPageQuery {
    content: markdownRemark(frontmatter: { templateKey: { eq: "home-page" } }) {
      frontmatter {
        title
        teamTitle
        teamContent
        teamButton
        teamImg {
          childImageSharp {
            fluid(maxWidth: 500, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconBlocks {
          content
          icon
          title
        }
        aboutTitle
        aboutContent
        serviceTitle
        serviceContent
        services
      }
    }
    homeVideo: file(relativePath: { eq: "videos/home-video.mp4" }) {
      publicURL
    }
  }
`;
